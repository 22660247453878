<template>
  <div class="autocode-report">
    <div
      v-if="!isLoading && STATISTICS.notFoundCars.length"
      class="cars">
      <div class="title">
        Не найденые автомобили
      </div>
      <ul>
        <li
          v-for="car in STATISTICS.notFoundCars"
          :key="car">
          {{ car }}
        </li>
      </ul>
    </div>

    <div
      v-loading="isLoading"
      class="reports">
      <div class="title">
        Отчет по автокоду
      </div>
      <el-collapse
        accordion
        @change="handlerColapse">
        <el-collapse-item name="1">
          <template slot="title">
            <el-button
              size="mini"
              type="primary">
              {{ spoilerTitle }}
            </el-button>
          </template>
          <ul
            class="structure__list">
            <li
              v-for="(item, i) in Object.entries(STATISTICS.avtocodReport || {})"
              :key="i">
              <list-item :item-data="item" />
            </li>
          </ul>
        </el-collapse-item>
      </el-collapse>
    </div>

    <pagination
      layout="prev, pager, next"
      :paginations-params="STATISTICS_PAGINATION"
      :paginate-callback="paginateHandler"
      :set-paginate="setStatisticsPagination" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
// eslint-disable-next-line import/no-unresolved
import Pagination from '@/components/ui/pagination';
import ListItem from './listItem.vue';

export default {
  name: 'AutocodeReport',
  components: {
    ListItem,
    Pagination,
  },
  data() {
    return {
      isListVisible: false,
    };
  },
  computed: {
    ...mapGetters(['STATISTICS', 'STATISTICS_PAGINATION', 'STATISTICS_REQUEST_STATUS']),
    spoilerTitle() {
      return `${this.isListVisible ? 'Закрыть' : 'Показать'} список`;
    },
    isLoading() {
      return this.STATISTICS_REQUEST_STATUS === 'requested';
    },
  },
  mounted() {
    this.setPageTitle('Отчет по автокоду');
    this.getStatistics(this.STATISTICS_PAGINATION);
  },
  methods: {
    ...mapMutations(['setPageTitle', 'setStatisticsPagination']),
    ...mapActions(['getStatistics']),
    async paginateHandler(currentPage) {
      const { perPage } = this.STATISTICS_PAGINATION;
      await this.getStatistics({
        perPage,
        page: currentPage,
      });
    },
    handlerColapse(val) {
      this.isListVisible = Number(val) === 1;
    },
  },
};
</script>
<style lang="scss">
  .autocode-report {
    .title {
      font-size: 1em;
      margin-bottom: 1em;
    }
    .reports {
      margin-top: 2em;
    }
    .cars {
      ul, li {
        text-align: left;
      }
      ul {
        font-family: 'Roboto Mono', monospace;
        font-weight: 300;
        padding-left: 1em;
      }
      li {
        font-size: 13px;
        list-style-type: none;
        color: #606266;
        font-weight: 400;
      }
    }
    .reports {
      ul, li {
        text-align: left;
      }
      ul {
        font-family: 'Roboto Mono', monospace;
        font-weight: 300;
        border-left: 1px dashed #dddddd;
        padding-left: 1em;
      }
      li {
        font-size: 13px;
        list-style-type: none;
        color: #606266;
        font-weight: 400;
        &:last-child {
          border-bottom: none;
        }
        span {
          height: 25px;
          display: flex;
          align-items: center;
        }
        b {
          text-transform: capitalize;
          margin-right: 0.5em;
        }
      }
    }
  }
</style>
